// @flow
import type { BbCollectedDatabaseServer, BbDatabaseServer, BbDatabaseSnapshot, BbDatabaseType, BbNestedDatabaseServer } from './type.dbs';
import type { BbCollectedLba, BbLba, BbNestedLba } from './type.lba';
import type { BbCloudIp, BbNestedCloudIp } from './type.cip';
import type { BbCollectedFirewallPolicy, BbFirewallPolicy, BbFirewallRule, BbNestedFirewallPolicy } from './type.fwp';
import type { BbApplication, BbCollectedApplication } from './type.app';
import type { BbAccount, BbCollectedAccount, BbNestedAccount } from './type.acc';
import type { BbApiClient } from './type.cli';
import type { BbCollectedServer, BbNestedServer, BbServer } from './type.srv';
import type { Megabytes } from './type.units';
import type { BbVolume, BbStorageType } from './type.volume';

export type BbScope = 'infrastructure' | 'orbit' | 'auth:two_factor';

export const BbResourceKinds = {
    'account': 'acc',
    'application': 'app',
    'api_client': 'cli',
    'cloud_ip': 'cip',
    'database_server': 'dbs',
    'database_snapshot': 'dbi',
    'database_type': 'dbt',
    'event': 'evt',
    'firewall_policy': 'fwp',
    'firewall_rule': 'fwr',
    'image': 'img',
    'interface': 'int',
    'load_balancer': 'lba',
    'server': 'srv',
    'server_group': 'grp',
    'server_type': 'typ',
    'user': 'usr',
    'collaboration': 'col',
    'zone': 'zon',
    'volume': 'vol',

    'test': 'tst',
    'test_unmapped': 'ts2',
};

export type BbResourceKind = $Keys<typeof BbResourceKinds>;

export type BbLockable = {
    locked: boolean,
}

export type BbShortResourceRef = {
    id: string,
    name: string,
    resource_type: BbResourceKind,
}

export type BbResource<T: BbResourceKind> = {
    id: string,
    resource_type: T,
    pristine: boolean,
};

export type BbNestedUser = {
    ...BbResource<'user'>,
    name: string,
    email_address: string,
    '2fa': ?{
        enabled: boolean,
    },
    gravatar_url: string,
};

export type BbTestCollected = {
    ...BbResource<'test'>,
    adapted: Date,
    nonAdapted: string,
};

export type BbTest = {
    ...BbTestCollected,
    fullOnly: boolean,
};

export type BbUserPatchParams = {
    name: string,
    email_address: string,
    messaging_pref: boolean,
    ssh_key: string,
    password: string,
};

export type BbUser = {
    ...BbResource<'user'>,
    name: string,
    email_address: string,
    email_verified: boolean,
    ssh_key: string,
    gravatar_url: string,
    messaging_pref: boolean,
    created_at: Date,
    '2fa': {
        enabled: boolean,
    },
    +accounts: $ReadOnlyArray<BbNestedAccount>,
};

export type BbImageStatus =
    'creating' |
    'available' |
    'deprecated' |
    'unavailable' |
    'deleting' |
    'deleted' |
    'failed' ;

export type BbImageSourceTrigger = 'manual' | 'schedule';

export type BbNestedImage = {
    ...BbResource<'image'>,
    ...BbLockable,
    url: string,
    name: string,
    username: string,
    status: BbImageStatus,
    description: string,
    source: string,
    source_trigger: string,
    arch: string,
    created_at: string,
    public: boolean,
    official: boolean,
    owner: string,
}

export type BbCollectedImage = {
    ...BbResource<'image'>,
    ...BbLockable,
    url: string,
    name: string,
    username: string,
    status: BbImageStatus,
    description: string,
    source: string,
    source_type: string,
    source_trigger: BbImageSourceTrigger,
    arch: string,
    created_at: Date,
    public: boolean,
    official: boolean,
    compatibility_mode: boolean,
    virtual_size: Megabytes,
    disk_size: Megabytes,
    min_ram: ?Megabytes,
    owner: string,
};

export type BbImage = {
    ...BbResource<'image'>,
    ...BbLockable,
    url: string,
    name: string,
    username: string,
    status: BbImageStatus,
    description: string,
    source: string,
    source_type: string,
    source_trigger: BbImageSourceTrigger,
    arch: string,
    created_at: Date,
    public: boolean,
    official: boolean,
    compatibility_mode: boolean,
    virtual_size: Megabytes,
    disk_size: Megabytes,
    min_ram: ?number,
    owner: string,
    licence_name: string,
    ancestor?: BbNestedImage,
};

export type BbImageParams = {
    name: string,
    description: string,
    public: boolean,
    arch: string,
    compatibility_mode: boolean,
    status: BbImageStatus,
};

export type BbServerTypeState = 'experimental' | 'available' | 'deprecated';

export type BbServerType = {
    ...BbResource<'server_type'>;
    url: string;
    handle: ?string;
    name: string;
    status: BbServerTypeState;
    cores: number;
    ram: Megabytes,
    disk_size: Megabytes,
    storage_type: BbStorageType,
};

export type BbZone = {
    ...BbResource<'zone'>;
    url: string,
    handle: string,
    name: string,
};

export type BbNestedServerGroup = {
    ...BbResource<'server_group'>;
    url: string,
    name: ?string,
    created_at: Date,
    description: ?string,
    default: boolean,
};

export type BbServerGroup = {
    ...BbResource<'server_group'>,
    url: string,
    name: ?string,
    created_at: Date,
    description: ?string,
    default: boolean,
    firewall_policy: ?BbNestedFirewallPolicy,
    servers: Array<BbNestedServer>,
};

export type BbServerGroupCreateParams = {
    name: string,
    description: string,
    server_ids: $ReadOnlyArray<string>,
};

export type BbServerGroupPatchParams = {
    name: string,
    description: string,
    servers: $ReadOnlyArray<{ server: string }>,
};

export type BbInterface = {
    ...BbResource<'interface'>;
    url: string;
    mac_address: string;
    ipv4_address: string;
    ipv6_address: string;
};

export type BbEvent = {
    ...BbResource<'event'>,
    account: BbShortResourceRef,
    action: string,
    short_action: string,
    affects: Array<BbShortResourceRef>,
    created_at: Date,
    id: string,
    identifier: string,
    resource: BbShortResourceRef,
    state: string,
    user: ?BbNestedUser,
    client: ?BbApiClient,
    message: string,
    short_message: string,
};

export type BbCollaborationStatus = 'pending' | 'accepted' | 'expired' | 'rejected' | 'cancelled' | 'ended' | 'active';
export type BbUserRole = 'owner' | 'admin';

export type BbCollaboration = {
    ...BbResource<'collaboration'>,
    status: BbCollaborationStatus,
    email: string,
    role: BbUserRole,
    created_at: Date,
    expires_at: ?Date,
    started_at: ?Date,
    finished_at: ?Date,
    role_label: string,
    user: ?BbNestedUser,
    account: BbNestedAccount,
    inviter: BbNestedUser,
    tfa_required: boolean,
};

export type BbCollaborationParams = {
    email: string,
    role: 'admin',
};

export type BbTargetKinds = '' | 'any' | 'srv' | 'grp' | 'lba' | 'dbs' | 'addr' | 'other' | 'clientIp';

export type AccessControlTarget = {
    kind: BbTargetKinds,
    value: string,
};

export type BbAllResourceTypes =
    BbAccount | BbNestedAccount | BbCollectedAccount |
    BbCollectedApplication | BbApplication |
    BbApiClient |
    BbCloudIp | BbNestedCloudIp |
    BbDatabaseServer | BbDatabaseSnapshot | BbDatabaseType | BbCollectedDatabaseServer | BbNestedDatabaseServer |
    BbEvent |
    BbFirewallPolicy | BbFirewallRule | BbCollectedFirewallPolicy |
    BbImage | BbCollectedImage | BbNestedImage |
    BbInterface |
    BbLba | BbCollectedLba | BbNestedLba |
    BbTest |
    BbNestedUser | BbUser | BbTestCollected |
    BbCollectedServer | BbServer | BbNestedServer |
    BbServerGroup | BbServerType | BbNestedServerGroup |
    BbCollaboration |
    BbZone |
    BbVolume
    ;

export type BbCollectedResourceTypes =
    BbCollectedApplication |
    BbCollectedAccount |
    BbApiClient |
    BbUser |
    BbCloudIp |
    BbEvent |
    BbCollectedImage |
    BbCollectedLba |
    BbCollectedServer |
    BbServerGroup |
    BbServerType |
    BbTestCollected |
    BbCollectedFirewallPolicy |
    BbCollectedDatabaseServer |
    BbDatabaseSnapshot |
    BbDatabaseType |
    BbCollaboration |
    BbVolume
    ;

export type BbFullResourceTypes =
    BbAccount |
    BbApplication |
    BbApiClient |
    BbCloudIp |
    BbDatabaseServer | BbDatabaseSnapshot | BbDatabaseType |
    BbEvent |
    BbFirewallPolicy | BbFirewallRule |
    BbImage |
    BbInterface |
    BbLba |
    BbTest |
    BbUser |
    BbServer |
    BbServerGroup | BbServerType |
    BbCollaboration |
    BbZone |
    BbVolume
    ;

export type BbTargetResourceTypes = BbCollectedServer | BbServerGroup | BbCollectedLba | BbCollectedDatabaseServer;

export type BbLockableTypes = BbServer | BbLba | BbDatabaseServer | BbDatabaseSnapshot | BbImage | BbVolume;