// @flow
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Settings, SettingsItem, SettingsArray } from './Setting/Settings';
import { ListGridSelector } from './ListGridSelector';
import { Button, TooltipButton } from './Button';
import { Status, formatDateTime, } from './Styled';
import { DropdownButton } from './DropdownButton';
import { initial } from './Chip';
import { Tooltip } from './Tooltip';
import { isShowDetailsResourceStatus, SkeletonBar } from './Skeleton';
import { RC_API_REQUEST, RC_INITIAL, RC_FETCHING } from '../../state/resource/type';
import { cn } from './lib/classNames';
import { Avatar } from './Avatar';
import { historyBack } from '../../lib/history';
import { BuildProgressBar } from './BuildProgressBar';

import type { SettingsItemProps } from './Setting/Settings';
import type { BbDatabaseEngineType, } from '../../api/type.dbs';
import type { SkeletonBarSize } from './Skeleton';
import type { ResourceCacheStatus, ResourceFetched, } from '../../state/resource/type';
import type { ListGridHook } from './UiStateHooks';
import type { BbServerGroup } from '../../api/type';
import type { MessageStatus } from '../../state/Message/type';
import type { OrbitContainerStatus } from '../../state/Orbit/type';
import type { ButtonProps } from './Button';
import type { BuildProgressKind } from './BuildProgressBar';
import type { TooltipProps } from './Tooltip'
import type { DialogState } from './Dialog';

type PanelProps = {
    +children: React$Node,
    +className?: string,

    +onDragOver?: (e: DragEvent) => void,
    +onDragExit?: (e: DragEvent) => void,
    +onDragLeave?: (e: DragEvent) => void,
    +onDrop?: (e: DragEvent) => void,
}

export type PanelHeaderActions = {
    edit?: ?() => void,
    unlock?: ?() => void,
    delete?: ?() => void,
    remove?: ?() => void,
    restart?: ?() => void,
    share?: ?() => void,
    cancel?: ?() => void,
    save?: ?() => void,
    snapshot?: ?() => void,
    unmap?: ?() => void,
    remap?: ?() => void,
    map?: ?() => void,
    start?: ?() => void,
    activate_console?: ?() => void,
    attach_volume?: ?() => void,
    resend?: ?() => void,
    cancel_invite?: ?() => void,
    browse?: ?number,
    settings?: ?string,
    download?: ?() => void,
    +copy?: ?() => void,

    +create_from_snapshot?: ?string,
    +create_from_image?: ?string,
    +create_from_volume?: ?string,
    add_rule?: string,
    add_access?: ?string,
    add_directory?: ?() => void,
    +container_settings?: string,


    powerOff?: ?{
        stop: () => void,
        shutdown: () => void,
        reset: () => void,
        reboot: () => void,
    }
}

type PanelHeaderProps = {
    +title: ?React$Node,
    +description?: ?React$Node,
    +actions?: ?PanelHeaderActions,
    +editTitleLink?: ?string,
    +disableDeleteHint?: ?React$Node,
    +disableEditHint?: ?React$Node,
    +disableAttachHint?: ?React$Node,
    +disableCopyHint?: ?React$Node,
    +disableSnapshotHint?: ?React$Node,
    +cacheStatus?: ?ResourceCacheStatus | ?MessageStatus,
    +mode?: ?ListGridHook,
};

type PanelBarProps = {
    +className?: string,
    +children: React$Node,
    +padding?: boolean,
    +border?: boolean,
}

export type PanelButtonBarProps = {
    +children?: React$Node,
    +cancel?: ?() => any,
    +invite?: ?() => any,
    +primaryButton?: ButtonProps,
    +leftButton?: ButtonProps,
    +leftButtonTooltip?: ?$Diff<$Shape<TooltipProps>, { children: React$Node }>,
    +cacheStatus?: ?(ResourceCacheStatus | MessageStatus | OrbitContainerStatus),
    +className?: string,
}

export const Panel = ({ children, className: ec, ...rest  }: PanelProps): React$Node => {

    let className = 'c-panel' + (ec ? ' ' + ec : '');

    return (
        <div className={className} {...rest}>
            {children}
        </div>
    );
};

export const PanelHeader = ({ title, description, actions, editTitleLink, disableEditHint, disableDeleteHint, disableAttachHint, disableCopyHint, disableSnapshotHint, cacheStatus, mode,  }: PanelHeaderProps): React$Node => {
    return (
        <PanelBar className='c-panel__section--header'>
            <h2 className='c-panel__title'>
                {!cacheStatus || isShowDetailsResourceStatus(cacheStatus)
                    ? title
                    : <SkeletonBar size='xxl' />
                }
                {editTitleLink
                    ? <Tooltip overlay={'Edit name'}>
                       <Link to={editTitleLink} className='c-panel__title-edit-link'>
                        <Button size='sm' color='grey' kind='tertiary' preIcon='note' className='c-panel__title-edit-button'/>
                    </Link>
                    </Tooltip>
                    : null}
                {disableEditHint && editTitleLink !== undefined
                    ? <Tooltip overlay={disableEditHint}>
                        <span className='c-panel__title-edit-link'>
                            <Button size='sm' kind='tertiary' className='c-panel__title-edit-button' disabled={true}>Edit</Button>
                        </span>
                    </Tooltip>
                    : null}

            </h2>

            {actions
                ? <div className='c-panel__header-actions'>

                    {mode ? <ListGridSelector mode={mode} /> : null}

                    {actions.hasOwnProperty('start') || actions.hasOwnProperty('powerOff')
                        ? <DropdownButton
                            content={'Power state'}
                            disabled={actions.powerOff === null && actions.start === null}
                            dropdown={<>
                                <Button size='sm' kind='tertiary' onClick={actions.start} disabled={!actions.start}>Start</Button>
                                <Button size='sm' kind='tertiary' onClick={actions.powerOff?.stop} disabled={!actions.powerOff?.stop}>Stop</Button>
                                <Button size='sm' kind='tertiary' onClick={actions.powerOff?.shutdown} disabled={!actions.powerOff?.shutdown}>Shutdown</Button>
                                <Button size='sm' kind='tertiary' onClick={actions.powerOff?.reboot} disabled={!actions.powerOff?.reboot}>Reboot</Button>
                                <Button size='sm' kind='tertiary' onClick={actions.powerOff?.reset} disabled={!actions.powerOff?.reset}>Reset</Button>
                            </>}
                        />
                        : null
                    }

                    {actions.hasOwnProperty('create_from_snapshot')
                        ? actions.create_from_snapshot
                            ? <Link to={actions.create_from_snapshot}>
                                <Button size='sm' kind='secondary'>
                                    Create Instance
                                </Button>
                            </Link>
                            : <Button size='sm' kind='secondary' disabled={true}>
                                Create Instance
                            </Button>
                        : null
                    }
                    {actions.hasOwnProperty('create_from_image')
                        ? actions.create_from_image
                            ? <Link to={actions.create_from_image}>
                                <Button size='sm' kind='secondary'>
                                    Create Cloud Server
                                </Button>
                            </Link>
                            : <Button size='sm' kind='secondary' disabled={true}>
                                Create Cloud Server
                            </Button>
                        : null
                    }
                    {actions.hasOwnProperty('create_from_volume')
                        ? actions.create_from_volume
                            ? <Link to={actions.create_from_volume}>
                                <Button size='sm' kind='secondary'>
                                    Create Cloud Server
                                </Button>
                            </Link>
                            : <Button size='sm' kind='secondary' disabled={true}>
                                Create Cloud Server
                            </Button>
                        : null
                    }
                    {actions.hasOwnProperty('browse') && actions.browse != null && actions.browse > 0
                        ? <Link to={'browse/'}><Button size='sm' kind='secondary'>View {actions.browse} Objects</Button></Link>
                        : null
                    }
                    {actions.hasOwnProperty('browse') && actions.browse === 0
                        ? <Tooltip overlay={<div>No objects in this container.</div>}>
                            <Button size='sm' kind='secondary' disabled={true}>View Objects</Button>
                        </Tooltip>
                        : null
                    }
                    {actions.hasOwnProperty('add_directory') && actions.add_directory != null
                        ? <Button size='sm' kind='secondary' onClick={actions.add_directory}>Add Directory</Button>
                        : null
                    }
                    {actions.hasOwnProperty('add_directory') && actions.add_directory == null
                        ? <Tooltip overlay="Directories can't be created in this container">
                            <Button size='sm' kind='secondary' disabled={true}>Add Directory</Button>
                        </Tooltip>
                        : null
                    }
                    {actions.hasOwnProperty('container_settings')
                        ? <Link to={'../'}><Button size='sm' kind='secondary'>Container Settings</Button></Link>
                        : null
                    }
                    {actions.hasOwnProperty('add_rule') ? <Link to={actions.add_rule || ''}><Button size='sm' kind='secondary'>Add Rule</Button></Link> : null}
                    {actions.hasOwnProperty('add_access') && actions.add_access != null
                        ? <Link to={actions.add_access || ''}><Button size='sm'>Add Access Rule</Button></Link>
                        : null
                    }
                    {actions.hasOwnProperty('add_access') && actions.add_access == null
                        ? <Tooltip overlay='Access to this container cannot be edited'>
                            <Button size='sm' disabled={true}>Add Access Rule</Button>
                        </Tooltip>
                        : null
                    }
                    {actions.hasOwnProperty('resend') ? <Button size='sm' kind='secondary' disabled={!actions.resend} onClick={actions.resend}>Resend Invite</Button> : null}
                    {actions.hasOwnProperty('edit')
                        ? <>
                            {disableEditHint != null
                                ? <Tooltip overlay={disableEditHint} placement='left'>
                                    <Button size='sm' kind='secondary' disabled={true}>Edit</Button>
                                </Tooltip>
                                : <Button size='sm' kind='secondary' disabled={!actions.edit} onClick={actions.edit}>Edit</Button>
                            }
                        </>
                        : null
                    }
                    {actions.hasOwnProperty('activate_console') ? <Button size='sm' kind='secondary' disabled={!actions.activate_console} onClick={actions.activate_console}>Activate Console</Button> : null}
                    {actions.hasOwnProperty('attach_volume')
                        ? <Tooltip overlay={disableAttachHint}>
                            <Button size='sm' kind='secondary' disabled={!actions.attach_volume} onClick={actions.attach_volume}>Attach Volume</Button>
                        </Tooltip>
                        : null}
                    {actions.hasOwnProperty('save') ? <Button size='sm' kind='secondary' disabled={!actions.save} onClick={actions.save}>Save</Button> : null}
                    {actions.hasOwnProperty('download') ? <Button size='sm' kind='secondary' disabled={!actions.download} onClick={actions.download}>Download</Button> : null}
                    {actions.hasOwnProperty('copy')
                        ? <Tooltip overlay={disableCopyHint}>
                            <Button size='sm' kind='secondary' disabled={!actions.copy || disableCopyHint != null} onClick={actions.copy}>Copy Volume</Button>
                        </Tooltip>
                        : null
                    }
                    {actions.hasOwnProperty('share') ? <Button size='sm' kind='secondary' disabled={!actions.share} onClick={actions.share}>Share</Button> : null}
                    {actions.hasOwnProperty('snapshot') && actions.snapshot != null
                        ? <Button size='sm' kind='secondary' disabled={!actions.snapshot} onClick={actions.snapshot}>Create Snapshot</Button>
                        : null}
                    {actions.hasOwnProperty('snapshot') && actions.snapshot == null
                        ? <Tooltip overlay={disableSnapshotHint} placement='left'>
                            <Button size='sm' kind='secondary' disabled={true} >Create Snapshot</Button>
                        </Tooltip>
                        : null}
                    {actions.hasOwnProperty('restart') && actions.restart != null
                        ? <Button size='sm' kind='secondary' onClick={actions.restart}>Restart</Button>
                        : null}
                    {actions.hasOwnProperty('unmap') ? <Button size='sm' kind='secondary' disabled={!actions.unmap} onClick={actions.unmap}>Unmap</Button> : null}
                    {actions.hasOwnProperty('remap') ? <Button size='sm' kind='secondary' disabled={!actions.remap} onClick={actions.remap}>Re-map</Button> : null}
                    {actions.hasOwnProperty('map') ? <Button size='sm' kind='secondary' disabled={!actions.map} onClick={actions.map}>Map</Button> : null}
                    {actions.hasOwnProperty('delete')
                        ? <>
                            {actions.unlock ?
                                <Tooltip
                                    placement={'topRight'}
                                    overlay={
                                        <span>
                                            This resource is currently locked and <br/>must be unlocked before it can be deleted<br/>
                                            <TooltipButton onClick={actions.unlock}>Unlock now</TooltipButton>
                                        </span>
                                    }>
                                    <Button size='sm' kind='secondary' color='red' onClick={actions.delete} disabled={true}>Delete</Button>
                                </Tooltip>
                                : null}

                            {actions.unmap
                                ? <Tooltip
                                    placement={'topRight'}
                                    overlay={
                                        <span>
                                            This IP is currently mapped and <br/>must be unmapped before it can be deleted<br/>
                                            <TooltipButton onClick={actions.unmap}>Unmap now</TooltipButton>
                                        </span>
                                    }>
                                    <Button size='sm' kind='secondary' color='red' onClick={actions.delete} disabled={true}>Delete</Button>
                                </Tooltip>
                                : null}

                            {disableDeleteHint
                                ? <Tooltip
                                    placement={'topRight'}
                                    overlay={disableDeleteHint}
                                >
                                    <Button size='sm' kind='secondary' color='red' onClick={actions.delete} disabled={true}>Delete</Button>
                                </Tooltip>
                                : null}

                            {!actions.unlock && !actions.unmap && !disableDeleteHint
                                ? <Button size='sm' kind='secondary' color='red' onClick={actions.delete} disabled={!actions.delete || (actions.unlock != null)}>Delete</Button>
                                : null
                            }
                        </>
                        : null
                    }
                    {actions.hasOwnProperty('cancel') ? <Button size='sm' kind='tertiary' color='grey' disabled={!actions.cancel} onClick={actions.cancel} preIcon='cross'/> : null}
                    {actions.hasOwnProperty('cancel_invite') ? <Button size='sm' kind='secondary' color='red' disabled={!actions.cancel_invite} onClick={actions.cancel_invite}>Cancel Invite</Button> : null}
                    {actions.hasOwnProperty('remove') ? <Button size='sm' kind='secondary' color='red' disabled={!actions.remove} onClick={actions.remove}>Remove</Button> : null}
                </div>
                : null}

            {mode && !actions ? <ListGridSelector mode={mode} /> : null}

            {description != null ? <div className='c-panel__description'>{description}</div> : null}
        </PanelBar>
    );
};


export const PanelBar = ({ children, className: extraClassName, padding, border }: PanelBarProps): React$Node => {

    let classes = {
        'c-panel__section': true,
        'c-panel__section--no-padding': (padding === false),
        'c-panel__section--no-border': (border === false),
        'c-panel__section--force-border': (border === true),
    }

    const className = cn(classes) + ' ' + (extraClassName || '');
    return (
        <div className={className}>
            {children}
        </div>
    );


};

export type PanelSearchBarProps = {
    +search: React$Node,
    +mode?: ?ListGridHook,
    +addButton?: ?{
        +url: string,
        +resourceName: string,
    },
    +addConfirmDialog?: ?DialogState<null>,
}

export const PanelSearchBar = ({ search, mode, addButton, addConfirmDialog }: PanelSearchBarProps): React$Node => (
    <PanelBar className='c-panel__section--view-options'>
        <div className='flex flex-wrap gap-y-4'>{search}</div>
        {mode ? <ListGridSelector mode={mode} /> : null}
        {addButton && !addConfirmDialog
            ? <Link to={addButton.url}><Button kind='primary'>Create {addButton.resourceName}</Button></Link>
            : null
        }
        {addButton && addConfirmDialog
            ? <Button kind='primary' onClick={() => addConfirmDialog.show()}>Create {addButton.resourceName}</Button>
            : null
        }
    </PanelBar>
);

export const PanelButtonBar = ({ cancel, invite, leftButton, leftButtonTooltip, cacheStatus, primaryButton, children, className, }: PanelButtonBarProps): React$Node => {
    const state = cacheStatus === RC_API_REQUEST ? 'loading' : '';

    return (
        <PanelBar className={'c-panel__section--button-bar' + (className ? ' ' + className : '')}>
            {leftButton !== undefined
                ? <>
                    {leftButtonTooltip
                        ? <Tooltip {...leftButtonTooltip}>
                            <Button
                                kind={leftButton.kind || 'tertiary'}
                                disabled={leftButton === null}
                                {...leftButton}
                            >
                                {leftButton.children}
                            </Button>
                        </Tooltip>
                        : <Button
                            kind={leftButton.kind || 'tertiary'}
                            disabled={leftButton === null}
                            {...leftButton}
                        >
                            {leftButton.children}
                        </Button>
                    }
                </>
                : null
            }
            {children}
            <div className='c-panel__section--button-bar__spacer'>&nbsp;</div>
            {cancel !== undefined
                ? <Button
                    kind='tertiary'
                    color='grey'
                    disabled={cancel === null}
                    onClick={cancel}
                >
                    Cancel
                </Button>
                : null}
            {invite !== undefined
                ? <Button
                    kind='primary'
                    disabled={invite === null}
                    state={state}
                    onClick={invite}
                >
                    Send Invite
                </Button>
                : null}
            {primaryButton !== undefined
                ? <Button
                    kind={primaryButton.kind || 'primary'}
                    disabled={primaryButton === null}
                    state={state}
                    {...primaryButton}
                >
                    {primaryButton.children || 'Save'}
                </Button>
                : null
            }
        </PanelBar>
    );
};

export const PanelResourceCreateButtons = ({ leftContent, children }: { leftContent?: React$Node, children: React$Node }): React$Node => (
    <PanelBar className='c-panel__section--button-bar'>
        {leftContent}
        {leftContent != null
            ? <div className='c-panel__section--button-bar__spacer'>&nbsp;</div>
            : null
        }
        <Button
            kind='tertiary'
            color='grey'
            onClick={() => historyBack()}
        >
            Cancel
        </Button>
        {children}
    </PanelBar>

)

export type DatabaseEngine = {
    engine: BbDatabaseEngineType,
    version: string,
}

type PanelDetailsBarProps = {
    +children: React$Node,
};

type DetailProps<T> = {
    +label: string,
    +value: ?T,
    +render?: ({ value: T }) => React$Node,
    +skeletonSize?: ?SkeletonBarSize,
}

// This was once used extensively by PanelDetailsBar.
// which is why it lives here...
export const Detail = <T>({ label, value, render, skeletonSize, }: DetailProps<T>): React$Node => (
    <div className='c-resource-details__item'>
        <div className='c-resource-details__item-name'>{label}</div>
        <div className='c-resource-details__item-value'>
            {value == null
                ? <SkeletonBar size={skeletonSize} />
                : render ? render({value}) : (value: any)
            }
        </div>
    </div>
);


export const PanelDetailsBar = ({ children }: PanelDetailsBarProps): React$Node => (
    <PanelBar className='c-panel__section--resource-details'>
        <div className='c-resource-details'>
            {children}
        </div>
    </PanelBar>
);

export type PanelSettingsBarProps = {
    +settings: $ReadOnlyArray<SettingsItemProps>,
    +children?: React$Node,
    +cacheStatus?: ResourceCacheStatus
}

export const PanelSettingsBar = ({ settings, children, cacheStatus, }: PanelSettingsBarProps): React$Node => {
    return (
        <PanelBar>
            <Settings>
                <SettingsArray settings={settings} cacheStatus={cacheStatus} />
                {children}
            </Settings>
        </PanelBar>
    );
}


export type PanelMultiSettingsBarProps = {
    +details?: {
        +id?: ?string,
        +status?: ?string,
        +progress?: ?number,
        +created_at?: ?Date,
        +expires_at?: ?Date,
        +revoked_at?: ?Date,
        +avatar?: ?BbServerGroup,
        +kind?: ?BuildProgressKind,
    },
    +settings: $ReadOnlyArray<$ReadOnlyArray<SettingsItemProps>>,
    +children?: React$Node,
    +cacheStatus?: ResourceCacheStatus,
    +startServer?: ?$PropertyType<PanelHeaderActions, 'start'>,
}

function useStartServerSpinner(startServer: ?$PropertyType<PanelHeaderActions, 'start'>): ?[?() => void, ResourceFetched] {
    const [state, setState] = useState<ResourceFetched>(RC_INITIAL);

    if (startServer != null) {
        return [
            state === RC_INITIAL
                ? () => {
                    startServer();
                    setState(RC_FETCHING);
                    setTimeout(() => setState(RC_INITIAL), 1000);
                } : null,
            state,
        ];
    }

    return null;
}

export const PanelMultiSettingsBar = ({ details, settings: allSettings, children, cacheStatus, startServer, }: PanelMultiSettingsBarProps): React$Node => {
    const show = (cacheStatus == null || isShowDetailsResourceStatus(cacheStatus));
    const serverButton = useStartServerSpinner(startServer);

    return (
        <PanelBar>
            {details && Object.keys(details).length > 0
                ? <Settings>
                    {details.hasOwnProperty('id')
                        ? <SettingsItem
                            name='ID'
                            summary={show ? <div className='c-identifier'>{details.id}</div> : <SkeletonBar />}
                            copyValue={details.id}
                        />
                        : null
                    }
                    {details.hasOwnProperty('status') && !details.hasOwnProperty('progress')
                        ? <SettingsItem
                            name='Status'
                            summary={show ? <Status status={details.status} revoked_at={details.revoked_at} /> : <SkeletonBar />}
                        />
                        : null
                    }
                    {details.hasOwnProperty('status') && details.hasOwnProperty('progress')
                        ? <SettingsItem
                            name='Status'
                            summary={show ?
                                <BuildProgressBar progress={details.progress} status={details.status} />
                                : <SkeletonBar
                                />
                            }
                            settingsBtn={show && serverButton != null && details.status === 'inactive'
                                ? {
                                    children: 'Start',
                                    kind: 'primary',
                                    onClick: serverButton[0],
                                    state: serverButton[1] === RC_FETCHING ? 'loading' : null
                                }
                                : null
                            }
                        />
                        : null
                    }
                    {details.hasOwnProperty('created_at')
                        ? <SettingsItem
                            name='Created'
                            summary={show ? formatDateTime(details.created_at) : <SkeletonBar />}
                        />
                        : null
                    }
                    {details.hasOwnProperty('expires_at')
                        ? <SettingsItem
                            name='Expires'
                            summary={show ? formatDateTime(details.expires_at) : <SkeletonBar />}
                        />
                        : null
                    }
                    {details.hasOwnProperty('avatar')
                        ? <SettingsItem
                            name='Avatar'
                            summary={show && details.avatar
                                ? <Avatar colorId={details.avatar?.id} initial={initial(details.avatar)}  />
                                : <SkeletonBar />
                            }
                        />
                        : null
                    }
                </Settings>
                : null
            }
            {allSettings.map((settings, i) => (
                <Settings key={i}>
                    <SettingsArray settings={settings} cacheStatus={cacheStatus} />
                    {children}
                </Settings>

            ))}
        </PanelBar>
    );
};