// @flow
import { useEffect } from 'react';
import { usePendingCollaborations } from '../../hoc/Collaboration';
import { Button } from '../../element/Button';
import { RC_SUCCESS, RC_INITIAL, RC_CACHED, RC_API_REQUEST } from '../../../state/resource/type';
import { useResourceRoutes, history } from '../../../lib/history';
import { Chip } from '../../element/Chip';
import { Card } from '../../element/Card';
import { Notice } from '../../element/Notice';
import { isAbleToAcceptCollaboration } from '../../../lib/user-collaborations';
import { kioskClient } from '../../../api/graphql';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

function useSignUpCollabs() {
    const getRoute = useResourceRoutes();
    const pending = usePendingCollaborations();
    const { collaborations, messages, cacheStatus } = pending;

    useEffect(() => {
        if (messages && messages.status === RC_SUCCESS && messages.resource) {
            if (messages.resource.status === 'accepted') {
                history.push(getRoute('dashboard', messages.resource?.account?.id));
            }
            messages.clear();
        }
    }, [messages, getRoute,]);

    useEffect(() => {
        if (cacheStatus === RC_CACHED && collaborations.length === 0) {
            history.push('/');
        }
    }, [cacheStatus, collaborations]);

    return {
        collab: collaborations.length ? collaborations[0] : null,
        ...pending,
    };
}

const KIOSK_USER_QUERY = gql`
query GetUserDetails {
    isEmailVerificationRequired
    user {
        id
        email
        isEmailVerified
        isTwoFactorEnabled
    }
}`;

export const CollabSignUp = (): React$Node => {
    const { collab, accept, reject, messages, } = useSignUpCollabs();

    // Fetch the User Details from the Kiosk API which has more specific
    // user details than the REST API
    const { loading, error, data } = useQuery(
        KIOSK_USER_QUERY,
        {
            client: kioskClient,
        }
    );

    // Map the Kiosk Response to the User Object as in the REST API
    const currentUser = {
        id: data?.user?.id,
        email_address: data?.user?.email,
        email_verified: data?.user?.isEmailVerified,
        "2fa": {
            enabled: data?.user?.isTwoFactorEnabled,
        },
        accounts: [],
    };

    // If there are no pending collaborations and the redirect failed
    // then show nothing. `useSignUpCollabs` should redirect before now.
    if (!collab || !currentUser) {
        return (
            <div className="c-auth">{null}</div>
        );
    };

    const isEmailVerificationRequired = data?.isEmailVerificationRequired;
    const isUserEmailVerified = data?.user?.isEmailVerified;
    const isAccountTwoFactorRequired = collab ? collab.tfa_required : false;
    const isUserTwoFactorEnabled = currentUser?.["2fa"]?.enabled || false;
    const isAcceptingTwoFactorRequired = isAccountTwoFactorRequired && !isUserTwoFactorEnabled;
    const isAcceptable = collab ? isAbleToAcceptCollaboration(currentUser, collab) : false;

    return (
        <div className="c-auth">
            <div className="c-auth__form">

                {loading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}

                <h1 className="c-auth__heading">
                    {collab.account?.name !== ''
                        ? 'Join the ' + collab.account?.name + ' account?'
                        : 'Accept Invitation from ' + collab.inviter?.name + '?'
                    }
                </h1>

                <Card className='c-auth__invite-card' selected={true}>
                    <Chip
                        name={collab.inviter?.name}
                        id={collab.inviter?.email_address}
                        colorId={collab.inviter?.id}
                        initial={(collab.inviter?.name || collab.inviter?.email_address).substr(0, 1).toUpperCase()}
                        gravatar_url={collab.inviter?.gravatar_url}
                    />
                </Card>

                <p className='mb-10'>{collab.inviter?.name} has invited you to access to their {<b>{collab.account?.name}</b>} Brightbox account as a collaborator.</p>

                {isEmailVerificationRequired && !isUserEmailVerified
                    ? <EmailVerificationRequiredSection
                        isEmailVerificationRequestable={false}
                        isEmailVerificationRequired={isEmailVerificationRequired}
                        isUserEmailVerified={isUserEmailVerified} />
                    : isAcceptingTwoFactorRequired
                        ? <TwoFactorRequiredSection
                            history={history}
                            isAcceptingTwoFactorRequired={isAcceptingTwoFactorRequired} />
                        : isAcceptable
                            ? <Button
                                kind='primary'
                                type='submit'
                                onClick={() => { accept(collab); }}
                                disabled={messages?.status !== RC_INITIAL}
                                state={messages?.status === RC_API_REQUEST ? 'loading' : ''}
                                className='c-auth__button'
                            >
                                Accept Invitation
                            </Button>
                            : null
                }

                <Button
                    color='grey'
                    onClick={() => { reject(collab); }}
                    disabled={messages?.status !== RC_INITIAL}
                    state={messages?.status === RC_API_REQUEST ? 'loading' : ''}
                    className='c-auth__button'
                >
                    Decline Invitation
                </Button>

                <DevOnly
                    collab={collab}
                    isAccountTwoFactorRequired={isAccountTwoFactorRequired}
                    isUserTwoFactorEnabled={isUserTwoFactorEnabled}
                    isEmailVerificationRequired={isEmailVerificationRequired}
                    isUserEmailVerified={isUserEmailVerified}
                />
            </div>
        </div>
    );
};

const EmailVerificationRequiredSection = ({
    isEmailVerificationRequestable,
    isEmailVerificationRequired,
    isUserEmailVerified,
}) => {
    if (isUserEmailVerified || !isEmailVerificationRequired) {
        return null;
    }

    return (
        <>
            <p>You will need to verify your email address before you can accept this invitation.</p>
        </>
    );
};

const TwoFactorRequiredSection = ({
    history,
    isAcceptingTwoFactorRequired,
}) => {
    if (!isAcceptingTwoFactorRequired) {
        return null;
    }
    return (
        <>
            <p>You will need to enable Two-Factor Authentication before you can accept this invitation.</p>
            <Button
                className='c-auth__button'
                kind='primary'
                onClick={() => history.push('/user/two_factor')}
            >
                Enable Two-Factor
            </Button>
        </>
    );
}

const DevOnly = ({
    collab,
    isAccountTwoFactorRequired,
    isEmailVerificationRequired,
    isUserEmailVerified,
    isUserTwoFactorEnabled,
}) => {
    if (process.env.NODE_ENV !== 'development') {
        return null;
    }

    return (
        <Notice className='bg-gray-100 mb-4 border border-dashed border-gray-400'>
            <h4>Dev only</h4>
            <p>
                Collab: {collab.id}
                <br />
                Account Two Factor Required: {isAccountTwoFactorRequired ? 'Yes' : 'No'}
                <br />
                User Two Factor Enabled: {isUserTwoFactorEnabled ? 'Yes' : 'No'}
                <br />
                Email Verification Required: {isEmailVerificationRequired ? 'Yes' : 'No'}
                <br />
                Email Verified: {isUserEmailVerified ? 'Yes' : 'No'}
            </p>
        </Notice>
    );
}
