// @flow

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { overwrite, getCodeList } from 'country-list';
import { useRouteMatch } from 'react-router-dom';
import { ACCOUNT_MATCH_STR, PROFILE_MATCH_STR } from '../element/animation';
import { LABELS } from '../element/ResourceLabels';

import type { BbCollaboration, BbUser } from '../../api/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { BbAccount } from '../../api/type.acc';
import type { ValueSelectChoice, ValueSelectOptions } from '../element/ValueSelect';
import type { Dispatch } from 'redux';
import type { FetchQueuePushAction } from '../../state/FetchQueue/type';

export const useCurrentUser = (): ?BbUser => {
    return useSelector((state: CloudGuiState) => state.Auth.currUser);
};

export const useCurrentAccountId = (): ?string => useSelector((state: CloudGuiState) => state.Auth.currAccountId ? state.Auth.currAccountId : null);
export const useAccountCacheKey = (): number => useSelector((state: CloudGuiState) => state.Auth.accountCacheKey);

// just a short delay to account for loads of places that call useCurrentAccount.
const ACCOUNT_QUEUE_FETCH_DELAY = 10;

export const useCurrentAccount = (): ?BbAccount => {
    const id = useCurrentAccountId();
    const dispatch = useDispatch<Dispatch<FetchQueuePushAction>>();

    const account = useSelector<CloudGuiState, ?BbAccount>((state: CloudGuiState) => {
        const { account } = state.Resource;
        if (id && account && account.full && account.full[id]) {
            return account.full[id];
        }

        return null;
    });

    useEffect(() => {
        if (account == null && typeof id === 'string') {
            dispatch({ type: 'FETCH_QUEUE_PUSH', payload: { kind: 'account', id, delay: ACCOUNT_QUEUE_FETCH_DELAY } });
        }
    }, [id, dispatch, account,]);

    return account;
};

export function getFakeOwnerCollab(account: ?BbAccount): ?BbCollaboration {
    return account
        ? {
            id: account.owner.id,
            resource_type: 'collaboration',
            pristine: true,
            status: 'active',
            email: account.owner.email_address,
            role: 'owner',
            created_at: account.created_at,
            started_at: account.created_at,
            finished_at: null,
            role_label: 'owner',
            user: account.owner,
            account: {
                id: account.id,
                resource_type: 'account',
                pristine: true,
                name: account.name,
                status: account.status,
            },
            inviter: account.owner,
        }
        : null;
}

overwrite([
    {
        code: 'gb',
        name: 'United Kingdom',
    },
]);

const COMMON_COUNTRY_CODES = [
    'gb', 'dk', 'fr', 'de', 'nl', 'no', 'ie', 'it', 'pt', 'es','us'
]

export const useCountryListChoices = (): ValueSelectOptions<string> => {
    return useMemo(() => {
        const byCode: { [string]: string } = getCodeList();

        const all = Object.keys(byCode)
            .sort((a: string, b: string) => byCode[a].localeCompare(byCode[b]))
            .reduce((acc, code: string) => {
                acc.push({ label: byCode[code], value: code.toUpperCase() });
                return acc;
            }, ([]: Array<ValueSelectChoice<string>>));

        const common = COMMON_COUNTRY_CODES.reduce((acc, code: string) => {
            acc.push({ label: byCode[code], value: code.toUpperCase() });
            return acc;
        }, ([]: Array<ValueSelectChoice<string>>))

        return [
            { label: 'Select...', value: '', },
            {
                groupLabel: 'Common',
                options: common,
            },
            {
                groupLabel: 'Others',
                options: all,
            }
        ]
    }, []);
}

export function useUrlParamValue(match: RegExp): ?string {
    const [value,] = useState(() => {
        const searchMatch = window.location.search.match(match);
        return searchMatch ? searchMatch[1] : null;
    });

    return value;
}

function mapResourceTypeFromRouteParam(kind: ?string): ?string {
    if (kind == null || !kind.length) return null;

    if (kind[kind.length -1] === 's') {
        kind = kind.substring(0, kind.length - 1);
    }

    // we let the regular ACCOUNT_MATCH_STR handle the "orbit/add" route
    if (kind === 'orbit') kind = 'container';
    if (kind === 'team') kind = 'user';
    if (kind === 'settings') kind = 'account';

    // fallback to avoid a crash
    if (!(kind in LABELS)) return null;

    return kind;
}

const ORBIT_MATCH_STR = '/accounts/:accountId/:ctr(orbit|container_registry)/container/:name?/:browsing?/';

export function useControlPanelHtmlTitles(): void {
    const [currentTitle, setCurrentTitle] = useState<?string>(null);
    useEffect(() => {
        if (document && currentTitle) {
            document.title = currentTitle;
        }
    }, [currentTitle]);

    const orbitMatch = useRouteMatch(ORBIT_MATCH_STR);
    const accountMatch = useRouteMatch(ACCOUNT_MATCH_STR);
    const profileMatch = useRouteMatch(PROFILE_MATCH_STR);

    let nextTitle = null;
    const singularResourceName: ?string = mapResourceTypeFromRouteParam(accountMatch?.params?.kind)
    if (orbitMatch) {
        const { name, browsing } = orbitMatch.params;
        const labelIndex = orbitMatch.params.ctr === 'container_registry' ? 'container_registry' : 'container';
        if (typeof name === 'string' && typeof browsing === 'string') {
            const params = new URLSearchParams(window.location.search);
            const path = '/' + (params.get('path') || '');
            nextTitle = path + ' - ' + name + ' - Container Objects';
        } else if (typeof name === 'string') {
            nextTitle = name + ' - ' + LABELS[labelIndex].title;
        } else {
            nextTitle = LABELS[labelIndex].listTitle;
        }
        // skip the add case - let ACCOUNT_MATCH_STR handle it
        // } else if (orbitMatch.params.n === 'add') {
        //     nextTitle = 'New ' + LABELS[singularResourceName].title;
    } else if (accountMatch && accountMatch.params.kind === 'billing') {
        nextTitle = 'Billing';
        if (accountMatch.params?.id === 'payment') nextTitle = 'Take Payment';
        if (accountMatch.params?.id === 'card') nextTitle = 'Update Card';
    } else if (accountMatch && accountMatch.params.kind === 'settings') {
        if (accountMatch?.params?.id === 'increase') {
            nextTitle = 'Account Limit Request';
        } else if (accountMatch.params.id === 'cli') {
            if (accountMatch.params.subedit === 'add') {
                nextTitle = 'New ' + LABELS.api_client.title;
            } else if (accountMatch.params.subedit) {
                nextTitle = accountMatch.params.subedit + ' - ' + LABELS.api_client.title;
            }
        } else {
            switch(window.location.hash) {
            default:
            case '#settings':
                nextTitle = 'Account Settings';
                break;
            case '#increase':
                nextTitle = 'Account Limits';
                break;
            case '#cli':
                nextTitle = 'API Access';
                break;
            }
        }
    } else if (accountMatch && singularResourceName) {
        if (singularResourceName === 'image' && !accountMatch.params.id) {
            switch (window.location.hash) {
            default:
            case '#mine':
                nextTitle = 'My Server Images';
                break;
            case '#official': nextTitle = 'Official Server Images'; break;
            case '#all': nextTitle = 'All Server Images'; break;
            }
        } else if (!accountMatch.params.id) {
            nextTitle = LABELS[singularResourceName].listTitle || LABELS[singularResourceName].plural;
        } else if (accountMatch.params.id === 'add') {
            nextTitle = 'New ' + LABELS[singularResourceName].title;
        } else if (accountMatch.params.id) {
            nextTitle = accountMatch.params.id + ' - ' + LABELS[singularResourceName].title;
        }
    } else if (profileMatch) {
        switch(profileMatch.params.editOrAppOrCollab) {
        case null:
        case void 0:
            switch (window.location.hash) {
            case '#accounts': nextTitle = LABELS.account.listTitle; break;
            case '#application': nextTitle = LABELS.application.listTitle; break;
            case '#settings': nextTitle = 'User Settings'; break;
            default: void 0; break;
            }
        break;
        case 'application':
            nextTitle = profileMatch.params.id
                ? profileMatch.params.id + ' - ' + LABELS.application.title
                : LABELS.application.listTitle;
            break;
        default: void 0; break;
        }
    }

    if (nextTitle != null) nextTitle += ' - Brightbox Control Panel';
    if (nextTitle === null) nextTitle = 'Brightbox Control Panel';

    if (nextTitle != null && nextTitle !== currentTitle) {
        setCurrentTitle(nextTitle);
    }
}