// @flow
import { useDialog } from '../element/Dialog';
import gql from 'graphql-tag';
import { useEditorErrors } from '../common/Editor';
import { useState, useEffect } from 'react';
import { useTriggeredMessages } from './Messages';
import { useRouteMatch } from 'react-router';
import { useHistory } from "react-router-dom";
import { useResourceFetchMutation } from './graphql';
import { kioskClient } from '../../api/graphql';
import { useDispatch } from 'react-redux';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS } from '../../state/resource/type';
import to from 'await-to-js';
import { historyBack } from '../../lib/history';
import QRCode from 'qrcode';

import type { DialogState } from '../element/Dialog';
import type { EditorModal } from '../common/Editor';
import type { BbUser } from '../../api/type';
import type { Dispatch } from 'redux';
import type { SecretAction } from '../../state/Secret/type';
import type { MessageStateAction } from '../../state/Message/type';

export type TwoFactorEditor = EditorModal<string, string, BbUser>;

type TwoFactorHook = {
    +editor: TwoFactorEditor,
    +disableDialog: DialogState<null>,
}

const DISABLE_2FA_MUTATION = gql`
mutation {
    disableTwoFactorEnrolment {
        user {
          isTwoFactorEnabled
        }
    }
}`;

const START_2FA_MUTATION = gql`
mutation {
    beginTwoFactorEnrolment {
        user {
          isTwoFactorEnabled
        }
        provisioningSecret
        provisioningUri
    }
}`;

const CONFIRM_2FA_MUTATION = gql`
mutation confirm ($totp: String!) {
    completeTwoFactorEnrolment(input: { totp: $totp }) {
        backupCodes
    }
}`;


export const useTwoFactorEditor = (matchPath: string, user: ?BbUser): TwoFactorHook => {
    const history = useHistory();
    const errors = useEditorErrors<string>('');
    const [value, setValue] = useState<?string>(null);
    const status = value === null ? false : 'edit';
    const { setErrors, clearErrors } = errors;
    const { next: nextDisableMessages, messages: disableMessages, } = useTriggeredMessages();
    const { next: nextEnableMessages, messages: enableMessages, } = useTriggeredMessages();

    const editUri = 'two_factor';
    const shouldEdit = useRouteMatch(matchPath + editUri + '/') !== null;

    const [confirm2fa,] = useResourceFetchMutation(
        'user', user?.id || '', CONFIRM_2FA_MUTATION, { client: kioskClient }
    );

    const [disable2fa,] = useResourceFetchMutation(
        'user', user?.id || '', DISABLE_2FA_MUTATION, { client: kioskClient }
    );

    const dispatch = useDispatch<Dispatch<SecretAction | MessageStateAction>>();

    useEffect(() => {
        if (shouldEdit && !status) {
            clearErrors();
            setValue('');
        }
    }, [shouldEdit, status, clearErrors, setValue, user]);

    const disableDialog = useDialog([
        {
            label: 'Disable',
            kind: 'primary',
            color: 'red',
            onSelect: async () => {
                const id = nextDisableMessages();
                dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_API_REQUEST, } });
                let [err,] = await to(disable2fa());
                if (err && err.message) {
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_ERROR, } });
                    setErrors(err.message);
                } else {
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_SUCCESS, } });
                    setValue(null);
                }
            }
        }
    ]);

    return {
        disableDialog,
        editor: {
            status,
            editUri,
            messages: user?.['2fa']?.enabled ? disableMessages : enableMessages,

            value,
            setValue,


            onCancel: () => {
                setValue(null);
                historyBack();
            },

            onSave: async () => {
                const saveUser = user, saveValue = value;

                if (saveUser && saveValue != null) {
                    if (saveUser['2fa'].enabled) {
                        // shouldn't get here; done in the disableTwoFactorDialog instead.
                    } else {
                        setErrors('');

                        if (!saveValue.match(/^[0-9]{6}$/)) {
                            setErrors('Please enter the code');
                            return;
                        }

                        const id = nextEnableMessages();
                        dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_API_REQUEST, } });

                        let tmp = await to(confirm2fa({ variables: { totp: saveValue } }));
                        let [err, res] = tmp;
                        if (err && err.message) {
                            setErrors(err.message);
                            dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_ERROR, } });
                        } else if (
                            res
                            && Array.isArray(res.data.completeTwoFactorEnrolment?.backupCodes)
                            && res.data.completeTwoFactorEnrolment.backupCodes.length > 0
                        ) {
                            dispatch({
                                type: 'SECRET_SET',
                                payload: {
                                    id: saveUser.id,
                                    value: res.data.completeTwoFactorEnrolment.backupCodes,
                                }
                            });

                            dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_SUCCESS, } });
                            setValue('');
                            // To keep signup flow consistent redirect to the
                            // collaborations list so the user can accept next.
                            history.push('/user/#accounts');
                        } else {
                            setErrors('Please check and re-enter your code');
                            dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_ERROR, } });
                        }
                    }
                }
            },

            ...errors,
        }
    };
};

export function useEnableTwoFactorAuth(user: BbUser): string {
    const [qrImgData, setQrImgData] = useState<string>('');
    const [start2fa, startStatus] = useResourceFetchMutation(
        'user', user?.id, START_2FA_MUTATION, { client: kioskClient }
    );
    const qrRawData = startStatus.data ? startStatus.data.beginTwoFactorEnrolment.provisioningUri : '';
    useEffect(() => {
        if (qrRawData !== '') {
            QRCode.toDataURL(qrRawData, { width: 224 }, function (err, url) {
                setQrImgData(url);
            });
        }
    }, [qrRawData]);

    useEffect(() => {
        start2fa();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return qrImgData;
}