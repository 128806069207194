// @flow

import { createPortal } from 'react-dom';
import { SvgIcon } from './SvgIcon';
import { MAX_UPLOAD_SIZE } from '../hoc/orbit/Upload';
import { displayBytesSize } from './Styled';

type DropFileOverlayProps = {
    destination: string,
    container: string,
}

const portalEl = document.getElementById('dialogs') || document.body;

export const DropFileOverlay = ({ destination, container }: DropFileOverlayProps): React$Node => {
    return createPortal(
        <div className='c-page-overlay'>
            {container === 'images'
                ? <div className='c-page-overlay__content'>
                    <h1 className='-mt-1 mb-4 text-2xl'><SvgIcon svg='warning'/> Upload Restricted</h1>
                    <h2 className='mb-6'>Files can't be uploaded directly to the images container</h2>
                </div>
                : <div className='c-page-overlay__content'>
                    <SvgIcon svg='upload' className='c-page-overlay__big-icon'/>
                    <h1 className='mb-2 text-3xl'>
                         Drop file to upload to</h1>
                         <h2 className='mb-6'>{destination}</h2>
                    <p className='opacity-50'>
                      <SvgIcon svg='info-fill' className='align-middle'/> <span>Maximum file size {displayBytesSize(MAX_UPLOAD_SIZE)}</span>
                    </p>
                </div>
            }
        </div>,
        portalEl
    );
};