// @flow

import { ProgressBar } from '../../element/ProgressBar';
import { Dialog } from '../../element/Dialog';
import { Notice } from '../../element/Notice';
import { Chip } from '../../element/Chip';
import { displayBytesSize } from '../../element/Styled';
import { Card } from '../../element/Card';
import { Separator } from '../../element/Separator';
import { MAX_UPLOAD_SIZE } from '../../hoc/orbit/Upload';
import { RadioGroup } from '../../element/RadioGroup';

import type { UploadableFile } from '../../hoc/orbit/Upload';
import type { DialogState } from '../../element/Dialog';
import type { MessageHook } from '../../hoc/Messages';

type OrbitUploadDialogProps = {
    +dialog: DialogState<Array<UploadableFile>>,
    +messages: MessageHook<null>,
    +setFileValue: (index: number, file: UploadableFile) => void,
    +uploadProgress: $ReadOnlyArray<?number>,
    +uploadableFiles: Array<UploadableFile>;
}

export const OrbitUploadDialog = ({ dialog, messages, setFileValue, uploadProgress, uploadableFiles }: OrbitUploadDialogProps): React$Node => (
    <Dialog
        dialog={dialog}
        title={(data: ?Array<UploadableFile>) => data && data.length > 1 ? 'Upload ' + data.length + ' Files?' : 'Upload File?'}
        messages={messages}
        render={
            () => (
                <>
                    {(uploadableFiles || []).map((f, i) => {
                        const { file, validSize, alreadyExists, overwrite } = f;
                        const progress = uploadProgress?.[i];

                        const showSkipped = progress != null && (
                            (alreadyExists && !overwrite)
                            || !validSize
                        );
                        const showSizeWarning = progress == null && !validSize;
                        const showOverwrite = progress == null && alreadyExists && validSize;
                        const showProgress = progress != null && validSize && (!alreadyExists || overwrite);

                        return (
                            <div key={i} className='flex items-center flex-col'>
                                {uploadableFiles && uploadableFiles.length > 1
                                    ? <Separator solid={true}>{i + 1}</Separator>
                                    : null
                                }

                                <Card selected={progress === 1.0}>
                                    <Chip
                                        name={file.name}
                                        id={displayBytesSize(file.size)}
                                        contentType={file.type || 'file'}
                                    />
                                </Card>
                                {showSizeWarning
                                    ? <Notice icon='warning-fill' type="warning" className="mt-3">
                                        <p>This file exceeds the maximum file upload size of {displayBytesSize(MAX_UPLOAD_SIZE)}.</p>

                                        <p>If you need to upload files larger than {displayBytesSize(MAX_UPLOAD_SIZE)} please use an <a href="https://www.brightbox.com/docs/reference/orbit/" target="_blank" rel="noopener noreferrer">alternative upload
                                        method</a> with support for Dynamic Large Objects.</p>
                                    </Notice>
                                    : null
                                }
                                {showOverwrite
                                    ? <div className='text-left'>
                                            <Notice icon='info-fill' type="warning" className="my-4">
                                            <div>
                                                An object with this name already exists
                                            </div>
                                            </Notice>
                                            <RadioGroup
                                                options={[
                                                    { label: 'Overwrite the existing object', value: true, },
                                                    { label: 'Skip this file', value: false, },
                                                ]}
                                                selected={overwrite}
                                                onSelect={(overwrite) => setFileValue(i, { ...f, overwrite })}
                                            />
                                        </div>
                                    : null
                                }
                                {showSkipped
                                    ? <Notice type="warning" className="mt-3">
                                        Skipped
                                    </Notice>
                                    :null
                                }
                                {showProgress
                                    ? <ProgressBar
                                        color="green"
                                        explanation={progress === 0 ? 'Waiting' : progress === 1.0 ? 'Completed' : 'Uploading'}
                                        max={1}
                                        value={progress || 0}
                                        className="u-width--card mx-auto"
                                    />
                                    : null
                                }

                            </div>
                        );
                    })}
                </>
            )
        }
    />
);