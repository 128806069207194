// @flow

import { useEffect, useState } from 'react';
import { useViewResource } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useDialog, Dialog } from '../../element/Dialog';
import { useCurrentUser } from '../../hoc/lib';
import { history } from '../../../lib/history';
import { ResourceChip } from '../../element/Chip';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router';
import type { BbCollaboration, BbCollaborationParams, } from '../../../api/type';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { ResourceActionMethod } from '../../../state/resource/type';
import type { DialogState } from '../../element/Dialog';
import type { DeleteDialogHook } from '../../common/CommonDialogs';
import type { PanelHeaderActions } from '../../element/Panel';

// If we delete a collab for the current user in an account,
// we'll lose access when it happens - so redirect the user
// their account page...
const useDeleteCurrUserCollabRedirect = (resource: ViewResourceProps<BbCollaboration, BbCollaborationParams>) => {
    const currUser = useCurrentUser();
    const { item: collab, status } = resource;
    const [sawNotDeleted, setSawNotDeleted] = useState<?boolean>(null);

    useEffect(() => {
        if (collab && collab.finished_at === null && !sawNotDeleted) {
            setSawNotDeleted(true);
        }

        if (sawNotDeleted === true && (collab?.status === 'cancelled' || collab?.status === 'rejected' || collab?.status === 'ended')) {
            history.push(collab?.user?.id === currUser?.id
                ? '/user/#accounts'
                : `/accounts/${collab?.account?.id || ''}/team/`
            );
        }
    }, [collab, status, currUser, setSawNotDeleted, sawNotDeleted]);
};

type ColDialogsHook = {
    actions: PanelHeaderActions,
    ...DeleteDialogHook,
    resendInviteDialog: DialogState<null>,
    cancelInviteDialog: DialogState<null>,
}

type ColDialogsProps = {
    +name: string,
    +hook: ColDialogsHook,
    +collaboration: ?BbCollaboration
}

export const useColDialogs = (
    item: ?BbCollaboration,
    simpleAction: (action: string, params?: ?$Shape<BbCollaborationParams>, nonPristine: ?BbCollaboration, method?: ?ResourceActionMethod) => void,
    deleteAction: () => void
): ColDialogsHook => {
    const resendInviteDialog = useDialog([{
        label: 'Re-send Invitation',
        kind: 'primary',
        onSelect: () => simpleAction('resend'),
    }]);

    const cancelInviteDialog = useDialog([{
        label: 'Cancel Invitation',
        kind: 'primary',
        onSelect: () => deleteAction(),
    }]);

    const deleteDialog = useDialog([{ label: 'Remove', kind: 'primary', color: 'red', onSelect: () => deleteAction(), }]);

    let actions: PanelHeaderActions = ({}: any);
    switch(item?.status) {
    case 'pending':
        actions['resend'] = resendInviteDialog.show;
        actions['cancel_invite'] = cancelInviteDialog.show;
        break;
    case 'accepted':
        actions['remove'] = deleteDialog.show;
        break;
    case 'expired':
    case 'cancelled':
    case 'rejected':
        actions['resend'] = null;
        actions['cancel_invite'] = null;
        break;
    case 'ended':
        actions['remove'] = null;
        break;
    default:
        break;
    }

    return {
        actions,
        resendInviteDialog,
        cancelInviteDialog,
        deleteDialog
    };
};

export const ColDialogs = ({ name, hook, collaboration }: ColDialogsProps): React$Node => {
    const currUser = useCurrentUser();

    return (
        <>
            <Dialog
                title='Resend Invitation?'
                dialog={hook.resendInviteDialog}
                render={() =>
                    <>
                        <p>Are you sure you want to re-send the invitation to {name}?</p>

                    </>
            }/>

            <Dialog
                title='Cancel Invitation?'
                dialog={hook.cancelInviteDialog}
                render={() =>
                    <>
                        <p>Are you sure you want to cancel the invitation to {name}?</p>

                    </>
            }/>

            <Dialog
                title='Remove Collaborator?'
                dialog={hook.deleteDialog}
                render={() => currUser?.id === collaboration?.user?.id
                    ? <>
                        <p>Are you sure you want to leave this team? </p>
                        <p>You will lose access to all resources within it.</p>
                    </>
                    : <p>Are you sure you want to remove {name} from this team?</p>
                }
            />
        </>
    );
};

export const CollaborationView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbCollaboration, BbCollaborationParams>('collaboration', id);
    useDeleteCurrUserCollabRedirect(resource);
    const { item, } = resource;

    const dialog = useColDialogs(item, resource.simpleAction, resource.deleteAction);

    let settings = [
        [{
            name: 'Email Address',
            summary: item?.user?.email_address || item?.email,
        }],
        [{
            name: 'Invited By',
            summary: item?.inviter ? <ResourceChip resource={item.inviter}/> : null
        }]
    ];

    if (item?.user) {
        settings[0].unshift({
            name: 'User',
            summary: <ResourceChip resource={item.user}/>,
        },{
            name: 'Two-Factor Authentication',
            summary: item.user['2fa'].enabled ? 'Yes' : 'No',
        });
    }

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.user.listTitle}
            resource={resource}
            match={match}
            dialog={
                <ColDialogs
                    name={item?.user?.email_address || item?.email || id}
                    hook={dialog}
                    collaboration={item}
                />
            }
            view={
                <Panel>
                    <PanelHeader
                        title={item?.user?.name || item?.email}
                        actions={dialog.actions}
                    />
                    <PanelMultiSettingsBar
                        cacheStatus={resource.status}
                        details={{
                            id: item?.id,
                            status: item?.status,
                            created_at: item?.created_at,
                            expires_at: item?.expires_at,
                            revoked_at: item?.finished_at,
                        }}
                        settings={settings}
                    />
                </Panel>
            }
        />
    );
};